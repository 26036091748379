import { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {TextInput} from '../../components/TextInput/index.js';
import Cookies from 'universal-cookie';
import { ClipLoader } from "react-spinners";
import {SideMenu} from '../../components/SideMenu/';
import {getClinics, getTimeslots, getPatientsBySearch, getBookings, createBooking, updateBooking, sendConfirmationEmails, sendReminderEmails, updateRecord, resendEmails, getMedicRecords,getProfileDiscover, getUserDetailsService, createStripeLoginLink} from '../../services/';
import styles from './styles.module.scss';
import getPaymentStatus from '../../utils/getPaymentStatus';
import filterBookings from '../../utils/filterBookings';

import plusIcon from '../../images/plus-icon.png';
import xButton from '../../images/x-button.png';
import profileURL from "../../images/default-profile.png";
import {MONTHS, SHORT_MONTHS, TIMES} from '../../config/times.config';
import {PAYMENT_STATUS_FILTERS} from '../../config/filters.config';

const STATUS_LIST = [
  'Pending',
  'Confirmed',
  'Cancelled'
]

export const Bookings = () => {
  const [user, setUser] = useState({});
  const [search,setSearch] = useState('');
  const [results, setResults] = useState([]);
  const [loading,setLoading] = useState(true);
  const [token,setToken] = useState(null);
  const [newBookingOpen,setNewBookingOpen] = useState(false);
  const [balance,setBalance] = useState(null);

  const [loadingForm,setLoadingForm] = useState(false);
  const navigate = useNavigate();

  const newDateRef = useRef(null);
  const [newDate, setNewDate] = useState(new Date());
  const [bookings, setBookings] = useState([]);

  const [paymentStatusFilter, setPaymentStatusFilter] = useState(PAYMENT_STATUS_FILTERS[0])
  const [filterStartDate, setFilterStartDate] = useState(null)
  const [filterEndDate, setFilterEndDate] = useState(null)

  const paymentStatusFilterRef = useRef(null)
  const filterStartDateRef = useRef(null)
  const filterEndDateRef = useRef(null)

  const [newPatientName, setNewPatientName] = useState('')
  const [newPatientEmail, setNewPatientEmail] = useState('')
  const [newPatientPhone, setNewPatientPhone] = useState('')

  const [editBookingPatientName, setEditBookingPatientName] = useState('')
  const [editBookingPatientEmail, setEditBookingPatientEmail] = useState('')
  const [editBookingPatientPhone, setEditBookingPatientPhone] = useState('')
  const [editBookingDob, setEditBookingDob] = useState('')
  const [editBookingDocuments, setEditBookingDocuments] = useState('[]')
  const [editBookingAddress, setEditBookingAddress] = useState('')
  const [editBookingId, setEditBookingId] = useState('')

  const [editBookingTimeslot, setEditBookingTimeslot] = useState('')
  const [editBookingClinic, setEditBookingClinic] = useState('')
  const [editBookingFee, setEditBookingFee] = useState('')
  const [editBookingCode, setEditBookingCode] = useState('')

  const [newBookingPatientId, setNewBookingPatientId] = useState(null)
  const [patients, setPatients] = useState([])
  const [clinics, setClinics] = useState([])
  const [newBookingClinic, setNewBookingClinic] = useState([])
  const [newBookingTimeslot, setNewBookingTimeslot] = useState([])

  const [newTimeslotClinic, setNewTimeslotClinic] = useState([])
  const [editTimeslotClinic, setEditTimeslotClinic] = useState('')
  const [editDate, setEditDate] = useState([])
  const [editBookingPatientId, setEditBookingPatientId] = useState(null)

  const [allTimeslots, setAllTimeslots] = useState([])
  const [availableTimeslots, setAvailableTimeslots] = useState([])
  const [newBookingDate, setNewBookingDate] = useState(new Date())
  const [editBookingDate, setEditBookingDate] = useState(new Date())
  const [newBookingFee, setNewBookingFee] = useState(0)

  const [noTimeslot, setNoTimeslot] = useState(false)
  const [useExistingTimeslot, setUseExistingTimeslot] = useState(false)

  const [sendEmail, setSendEmail] = useState(true)

  const [code, setCode] = useState(null)

  const [records, setRecords] = useState([]);

  const DOMAIN = "https://portal.rumu-health.com"

  const getReferralDate = (dateString, time)=>{
    let date = dateString.replaceAll(' ','').replaceAll('.','/').split('/')

    let d = date[0],
      m = parseInt(date[1]) - 1,
      y = date[2];

    let h = time.replaceAll(';',':').replaceAll('pm','').replaceAll('PM','').replaceAll('am','').replaceAll('AM','').split(':')[0],
        min = time.replaceAll(';',':').replaceAll('pm','').replaceAll('PM','').replaceAll('am','').replaceAll('AM','').split(':')[1];

      if(y && y.length == 2) y = '20'+y

    if(!d || !m || !y || !h || !min) return 'error'


    const timeInt = Date.parse(d+' '+SHORT_MONTHS[m]+' '+y+' '+h+':'+min+':00 GMT')

    return timeInt
  }

  const getDate = (date, noTime = false)=>{
    let newDate = new Date(date);
    return newDate.getDate()+" "+(MONTHS[newDate.getMonth()].short)+" "+newDate.getFullYear()
  }

  const getDateTime = (date, noTime = false)=>{
    let newDate = new Date(date);
    return newDate.getDate()+"/"+(newDate.getMonth()+1)+"/"+newDate.getFullYear()+" "+newDate.getHours()+":"+((newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes())
  }


  const init = async()=>{
    const cookies = new Cookies();
    const token = await cookies.get('token');
    if(!token){
      navigate('/');
    }else{
      setToken(token);
      const loadedBookingsData = await getBookings({token})
      if(loadedBookingsData.data.success){
        setBookings(loadedBookingsData.data.bookings.reverse());
        setBalance(loadedBookingsData.data.balance);
        console.log(loadedBookingsData.data.bookings)
      }else{
        alert("Error getting your bookings.");
      }

      const loadedTimeslotsData = await getTimeslots({token})
      if(loadedTimeslotsData.data.success){
        setAllTimeslots(loadedTimeslotsData.data.timeslots)
        setAvailableTimeslots(loadedTimeslotsData.data.availableTimeslots)
      }else{
        alert("Error getting your timeslots.");
      }

      const loadedClinicsData = await getClinics({token})
      if(loadedClinicsData.data.success){
        console.log('FGH')
        console.log(loadedClinicsData.data.clinics)
        setClinics(loadedClinicsData.data.clinics);
      }else{
        alert("Error getting your clinics.");
      }

      const userDetails = await getUserDetailsService(token,{});
      if(userDetails.data.success){
        setUser(userDetails.data);
      }else{
        console.log(userDetails);
        alert("Error getting your details.");
      }

      const recordDetails = await getMedicRecords({token});
      if(recordDetails.data.success){
        setRecords(recordDetails.data.records.sort((a,b)=>{

          if(getReferralDate(a.appointmentDate, a.appointmentTime) == 'error' && getReferralDate(b.appointmentDate, b.appointmentTime) != 'error') return 1;
          if(getReferralDate(a.appointmentDate, a.appointmentTime) != 'error' && getReferralDate(b.appointmentDate, b.appointmentTime) == 'error') return -1;
          if(getReferralDate(a.appointmentDate, a.appointmentTime) == 'error' && getReferralDate(b.appointmentDate, b.appointmentTime) == 'error') return 0;


          if(getReferralDate(a.appointmentDate, a.appointmentTime) > getReferralDate(b.appointmentDate, b.appointmentTime)){
            return -1
          }else if(getReferralDate(a.appointmentDate, a.appointmentTime) < getReferralDate(b.appointmentDate, b.appointmentTime)){
            return 1
          }

          return 0
        }));
      }else{
        alert("Error getting your records.");
      }

      setLoading(false);
    }

  }


  useEffect(()=>{
    init();
  },[]);


  if(loading){
    return(
      <div className="global-loading-page">
        <div className="global-loader">
          <ClipLoader  size={30} color={"#147d61"} />
        </div>
      </div>
    )
  }

  const renderPaymentCode = ()=>{
    if(!code) return;

    //Form Should Contain: Timeslot (includes clinic), searched patient, price
    return(
      <div className={styles.ordersNewProductWindowContainer}>
        <div className={styles.ordersNewProductWindow}>
          <img className={styles.ordersXButton} src={xButton} onClick={(event)=>{setCode(null)}} />
          <div className={styles.ordersRecentBookingsTitleContainer}>
            <div className={styles.ordersNewProductHeader}>
              <img className={styles.ordersRecentBookingsTitleIcon} src={require('../../images/logo-main.png')}/>
              <div className={styles.ordersRecentBookingsTitleText}>Booking Payment Link</div>
            </div>
          </div>
          <div className={styles.bookingCompleteForm}>
              Your Booking Was Created! <br/><br/>
              The payment link for your client is: <br/><br/>
              {DOMAIN}/pay?c={code}<br/><br/>
              The timeslot choosing link for your client is: <br/><br/>
              {DOMAIN}/set-time?c={code}
          </div>
        </div>
      </div>
    )
  }


  const renderNewBooking = ()=>{
    if(!newBookingOpen) return;

    //Form Should Contain: Timeslot (includes clinic), searched patient, price
    return(
      <div className={styles.ordersNewProductWindowContainer}>
        <div className={styles.ordersNewProductWindow}>
          <img className={styles.ordersXButton} src={xButton} onClick={(event)=>{setNewBookingOpen(false)}} />
          <div className={styles.ordersRecentBookingsTitleContainer}>
            <div className={styles.ordersNewProductHeader}>
              <img className={styles.ordersRecentBookingsTitleIcon} src={require('../../images/logo-main.png')}/>
              <div className={styles.ordersRecentBookingsTitleText}>New Booking</div>
            </div>
          </div>
          <div className={styles.ordersForm}>

          <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
            <div className={"global-dashboard-row"}>
              <div style={{marginLeft:"10px", marginRight:"10px"}} className={"global-dashboard-row-card-white"}>

                <div className={styles.cardHeader}>New Booking Details</div>
                <div className={styles.cardColumnContainer}>
                  <div className={styles.cardColumn}>
                  <TextInput placeholder="Patient"  type="form-search" onChangeText={async(value)=>{
                    const cookies = new Cookies();
                    const token = cookies.get('token');

                    let res = await getPatientsBySearch({token, search:value})

                    if(res.data.success){
                      setPatients(res.data.patients)
                    }
                  }} options={patients} onChange={(patient)=>{setNewBookingPatientId(patient.id)}} initialValue={''}/>
                  <br/><br/>
                  or create a patient
                  <br/><br/>
                  <TextInput placeholder="Patient Name" noHeader={true} type="form-text" onChange={(value)=>{setNewPatientName(value)}} initialValue={''}/>
                  <TextInput placeholder="Patient Phone" noHeader={true} type="form-text" onChange={(value)=>{setNewPatientPhone(value)}} initialValue={''}/>
                  <TextInput placeholder="Patient Email" noHeader={true} type="form-text" onChange={(value)=>{setNewPatientEmail(value)}} initialValue={''}/>
                  <br/><br/>
                  <TextInput placeholder="Total Fee (€)" noHeader={true} type="form-text" onChange={(value)=>{setNewBookingFee(value)}} initialValue={''}/>

                  </div>
                  <div className={styles.cardColumn}>
                    <TextInput placeholder="Clinic"  type="clinics-dropdown" options={clinics} onChange={(clinic)=>{
                      setNewBookingClinic(clinic)
                    }} initialValue={''}/><br/><br/>
                    <TextInput placeholder="Date Filter" highlightedDates={availableTimeslots.filter((t)=>{ return (t.clinicId == newBookingClinic)}).map((time)=> new Date(time.startTime))} type={'search-date'} onChange={(e)=>{

                      let date = new Date(e)
                      setNewBookingDate(date)

                    }} initialValue={new Date()} />
                    <TextInput placeholder="Timeslots" type="timeslots-dropdown" options={availableTimeslots.filter((t)=>{ return (t.clinicId == newBookingClinic) && (getDate(t.startTime) == getDate(newBookingDate)) })} onChange={(timeslot)=>{setNewBookingTimeslot(timeslot)}} initialValue={''}/>
                    <br/><br/>
                    Or create new timeslot:
                    <TextInput placeholder="Clinic"  type="clinics-dropdown" options={clinics} onChange={(clinic)=>{
                      setNewTimeslotClinic(clinic)
                    }} initialValue={''}/><br/><br/>
                    <TextInput placeholder="Date*" id="abc"  type="date" ref={newDateRef} onChange={(value)=>{setNewDate(value)}} initialValue={newDate}/>
                    <br/><br/>
                    <span style={{paddingRight:"0px"}}><input type="checkbox" id="checkbox1" name="checkbox1" checked={noTimeslot} onChange={(event)=>{setNoTimeslot(!noTimeslot)}}></input> Let Patient Choose timeslot</span>
                  </div>
                </div>

                <br/><br/>
                  <span style={{paddingRight:"0px"}}><input type="checkbox" id="checkbox1" name="checkbox1" checked={sendEmail} onChange={(event)=>{setSendEmail(!sendEmail)}}></input> Send Payment Email To Patient</span>
                <br/><br/>
                <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Save Booking" onClick={async(event)=>{
                  console.log(sendEmail,newBookingPatientId,newPatientEmail?.length)
                if(sendEmail && !(newBookingPatientId || newPatientEmail?.length)){
                  alert('Please enter an email to send the payment link to the client.')
                  return
                }
                  await setLoading(true)
                  const cookies = new Cookies();
                  const token = await cookies.get('token');
                  await setLoadingForm(true)
                  const createRes = await createBooking({
                    noTimeslot,
                    timeslot:newBookingTimeslot,
                    patient:newBookingPatientId,
                    fee:newBookingFee,
                    clinicId:newTimeslotClinic,
                    startTime:new Date(newDate).getTime(),
                    newPatientName,
                    newPatientEmail,
                    newPatientPhone,
                    token,
                    sendEmail
                  });
                  if(createRes.data.success){
                    setBookings(createRes.data.bookings.reverse())
                    setAvailableTimeslots(createRes.data.availableTimeslots)
                    setCode(createRes.data.code)
                    await setNewBookingOpen(false)
                  }else{
                    console.log(createRes.data)
                    alert((createRes.data)?createRes.data.error:'Error creating booking.');
                  }

                  await setLoading(false)
                }} />

                <br/>
              </div>
            </div>

            <div className={"global-dashboard-row"}>

            </div>
          </div>

            <div className={styles.formButtonContainer}>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderEditBooking = ()=>{
    if(!editBookingId) return;

    return(
      <div className={styles.ordersNewProductWindowContainer}>
        <div className={styles.ordersNewProductWindow}>
          <img className={styles.ordersXButton} src={xButton} onClick={(event)=>{setEditBookingId(false)}} />
          <div className={styles.ordersRecentBookingsTitleContainer}>
            <div className={styles.ordersNewProductHeader}>
              <img className={styles.ordersRecentBookingsTitleIcon} src={require('../../images/logo-main.png')}/>
              <div className={styles.ordersRecentBookingsTitleText}>Edit Booking</div>
            </div>
          </div>
          <div className={styles.ordersForm}>

          <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
            <div className={"global-dashboard-row"}>
              <div style={{marginLeft:"10px", marginRight:"10px"}} className={"global-dashboard-row-card-white"}>

                <div className={styles.cardHeader}>Edit Booking Details</div>
                <div className={styles.cardColumnContainer}>
                  <div className={styles.cardColumn}>
                  <br/><br/>
                  Patient Details
                  <br/><br/>
                  Name: {editBookingPatientName}<br/><br/>
                  Email: {editBookingPatientEmail}<br/><br/>
                  Phone: {editBookingPatientPhone}<br/><br/>
                  Address: {editBookingAddress}<br/><br/>
                  DOB: {editBookingDob}<br/><br/>
                  Uploaded Documents: {editBookingDocuments.map((document,index)=>{
                    return <a href={document}>Document {index}</a>
                  })}<br/><br/>
                  <br/><br/>
                  Payment link: {DOMAIN}/pay?c={editBookingCode}
                  <br/><br/>
                  Fee (€):<br/>
                  <TextInput placeholder="Total Fee (€)" noHeader={true} type="form-text" onChange={(value)=>{setEditBookingFee(value)}} initialValue={editBookingFee}/>

                  </div>
                  <div className={styles.cardColumn}>
                    <span style={{paddingRight:"0px"}}><input type="checkbox" id="checkbox1" name="checkbox1" checked={useExistingTimeslot} onChange={(event)=>{setUseExistingTimeslot(!useExistingTimeslot)}}></input> Use pre-existing timeslot</span>
                  
                    {
                      (useExistingTimeslot)?
                      <>
                      <TextInput placeholder="Clinic"  type="clinics-dropdown" options={clinics} onChange={(clinic)=>{
                        setEditBookingClinic(clinic)
                      }} initialValue={editBookingClinic}/><br/><br/>
                      <TextInput placeholder="Date Filter" highlightedDates={availableTimeslots.filter((t)=>{ return (t.clinicId == editBookingClinic)}).map((time)=> new Date(time.startTime))} type={'search-date'} onChange={(e)=>{

                        let date = new Date(e)
                        setEditBookingDate(date)

                      }} initialValue={editBookingDate?(new Date(editBookingDate)): Date.now()} />
                      <TextInput placeholder="Timeslots"  type="timeslots-dropdown" options={availableTimeslots.filter((t)=>{ return (t.clinicId == editBookingClinic) && (getDate(t.startTime) == getDate(editBookingDate)) })} onChange={(timeslot)=>{setEditBookingTimeslot(timeslot)}} initialValue={editBookingTimeslot}/>
                      <br/><br/>
                      </>
                      :
                      <>
                        <TextInput placeholder="Clinic"  type="clinics-dropdown" options={clinics} onChange={(clinic)=>{
                          setEditTimeslotClinic(clinic)
                        }} initialValue={''}/><br/><br/>
                        <TextInput placeholder="Date Filter" type={'date'} onChange={(e)=>{

                        let date = new Date(e)
                        setEditBookingDate(date)

                        }} initialValue={new Date()} /> 
                      </>
                    }
                  </div>
                </div>
                <br/>

                <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Save Booking" onClick={async(event)=>{
                  await setLoading(true)
                  const cookies = new Cookies();
                  const token = await cookies.get('token');
                  await setLoadingForm(true)
                  const createRes = await updateBooking({
                    bookingId:editBookingId,
                    timeslot:(useExistingTimeslot)?editBookingTimeslot:null,
                    fee:editBookingFee,
                    clinicId:editTimeslotClinic,
                    startTime:(!useExistingTimeslot)?(new Date(editBookingDate).getTime()):null,
                    token
                  });
                  if(createRes.data.success){
                    setBookings(createRes.data.bookings.reverse())
                    setAvailableTimeslots(createRes.data.availableTimeslots)
                    await setNewBookingOpen(false)
                  }else{
                    console.log(createRes.data)
                    alert('Error creating booking.');
                  }

                  await setLoading(false)
                }} />

                <br/>
              </div>
            </div>

            <div className={"global-dashboard-row"}>

            </div>
          </div>

            <div className={styles.formButtonContainer}>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if(token){
    return(
      <div className="global-container">
        {renderNewBooking()}
        {renderEditBooking()}
        {renderPaymentCode()}
        <div className={styles.homeContentContainerMain}>

          <SideMenu open={true} activeScreen={"Bookings"} name={user.profileName} />
          <div className={styles.homeDashboardContentMain}>

            <div className="global-header">
              <div className="global-header-profile">
                <img src={user.profileURL || profileURL} className="global-header-profile-image" />
                <div className="global-header-profile-text">{user.name}</div>
              </div>


              <div className="global-header-buttons">
              </div>
            </div>

            <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
              <div className={"global-dashboard-row"}>
                <div style={{marginLeft:"50px", marginRight:"50px", height:"85vh", display:"flex",flexDirection:"column", overflowY:"auto"}} className={"global-dashboard-row-card-white"}>
                  <div className={styles.cardHeader}>
                  <div className={styles.cardHeaderTitle}>
                    <img src={require('../../images/booking-icon.png')} className={styles.cardHeaderIcon} />
                    My Bookings
                  </div>
                  <div className={styles.newBookingContainer}>
                    <TextInput large={true} style={{marginRight:'10px',marginTop:'30px', verticalAlign:'middle'}} type="submit-input" icon={plusIcon} placeholder="Create Booking" onClick={async(event)=>{
                      setNewPatientEmail('')
                      setNewPatientName('')
                      setNewBookingOpen(true)
                    }} />
                    <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Stripe" onClick={async(event)=>{
                      setLoading(true)
                      const linkRes = await createStripeLoginLink({token})
                     if(!linkRes.data.success){
                      alert("Error. Please try again.")
                      return
                     }
                     console.log(linkRes.data)
                      window.open(linkRes.data.loginLink.url)
                      setLoading(false)
                     }} />
                     {(balance)?'Stripe Balance: €'+balance:''}
                  </div>
                  <div className={styles.searchContainer}>
                    <TextInput  placeholder="Search From Date*"   highlightedDates={availableTimeslots.map((time)=> new Date(time.startTime))} type="date" ref={filterStartDateRef} onChange={(value)=>{setFilterStartDate(value.getTime())}} initialValue={null}/>
                    <TextInput  placeholder="Search To Date*"  highlightedDates={availableTimeslots.map((time)=> new Date(time.startTime))} type="date" ref={filterEndDateRef} onChange={(value)=>{setFilterEndDate(value.getTime())}} initialValue={null}/>
                    <TextInput ref={paymentStatusFilterRef} placeholder="Status" type="dropdown" options={PAYMENT_STATUS_FILTERS} onChange={(value)=>{setPaymentStatusFilter(value)}} initialValue={PAYMENT_STATUS_FILTERS[0]}/>

                    <TextInput large={false} style={{marginRight:'10px',marginTop:'30px', verticalAlign:'middle'}} type="submit-input" placeholder="Clear" onClick={async(event)=>{
                      setFilterStartDate(null)
                      setFilterEndDate(null)
                      setPaymentStatusFilter(PAYMENT_STATUS_FILTERS[0])
                      await paymentStatusFilterRef.current.setTextboxValue(PAYMENT_STATUS_FILTERS[0])
                      await filterStartDateRef.current.setTextboxValue(null)
                      await filterEndDateRef.current.setTextboxValue(null)
                    }} />

                    
                  </div>
                  </div>


                  <table style={{overflowY:"auto", flex:"1"}}>
                    <tr className={styles.ordersRecentBookingsTableHeader}>
                      <th className={styles.productsResultHeaderItem}>Patient</th>
                      <th className={styles.productsResultHeaderItem}>Status</th>
                      <th className={styles.productsResultHeaderItem}>Clinic</th>
                      <th className={styles.productsResultHeaderItem}>Date</th>
                      <th className={styles.productsResultHeaderItem}>Edit</th>
                    </tr>
                    {
                      filterBookings(bookings || [],{paymentStatusFilter,filterStartDate,filterEndDate}).map((record, recordIndex)=>{

                        if(search.length > 0 && !search.toUpperCase().includes(record.name.toUpperCase()) && !record.name.toUpperCase().includes(search.toUpperCase()) && !search.toUpperCase().includes(record.phone.toUpperCase()) && !record.phone.toUpperCase().includes(search.toUpperCase())) return

                        if(recordIndex % 2 != 0){
                          return(
                            <tr className={styles.productsRowEven} style={{backgroundColor:(record.status == 2)?'#ff667d':'#ffffff'}} onClick={async(event)=>{

                            }}>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>{record['m5_patient']?.name}</td>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>{getPaymentStatus(record)}</td>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>{record['m5_timeslot']?.clinicId}</td>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>{record['m5_timeslot']?getDateTime(record['m5_timeslot']?.startTime):'Patient To Select'}</td>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>
                                <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Edit Booking" onClick={async(event)=>{
                                  console.log(record)
                                  setEditBookingPatientName(record.m5_patient?.name)
                                  setEditBookingAddress(record.m5_patient?.address)
                                  setEditBookingPatientPhone(record.m5_patient?.phone)
                                  setEditBookingPatientEmail(record.m5_patient?.email)
                                  setEditBookingDob(record.m5_patient?.dob)
                                  setEditBookingDocuments(JSON.parse(record.documentURLs || '[]'))
                                  setEditBookingFee(record.fee)
                                  setEditBookingCode(record.code)
                                  setEditBookingClinic(record.m5_timeslot?.clinicId)
                                  setEditBookingTimeslot(record.timeslotId)
                                  setEditBookingDate(record.m5_timeslot?.startTime)
                                  setUseExistingTimeslot(true)
                                  setEditBookingId(record.id)
                                }} />
                              </td>
                            </tr>
                          )
                        }

                        return(
                          <tr className={styles.productsRowOdd} style={{backgroundColor:(record.status == 2)?'#ff667d':'#f5f6ff'}} onClick={async(event)=>{

                          }}>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>{record['m5_patient']?.name}</td>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>{getPaymentStatus(record)}</td>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>{record['m5_timeslot']?.clinicId}</td>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>{record['m5_timeslot']?getDateTime(record['m5_timeslot']?.startTime):'Patient To Select'}</td>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>
                              <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Edit Booking" onClick={async(event)=>{
                                console.log(record)
                                setEditBookingPatientName(record.m5_patient?.name)
                                  setEditBookingAddress(record.m5_patient?.address)
                                  setEditBookingPatientPhone(record.m5_patient?.phone)
                                  setEditBookingPatientEmail(record.m5_patient?.email)
                                  setEditBookingDob(record.m5_patient?.dob)
                                  setEditBookingDocuments(JSON.parse(record.documentURLs || '[]'))
                                  setEditBookingClinic(record.clinic)
                                  setEditBookingTimeslot(record.clinic)
                                  setEditBookingFee(record.fee)
                                  setEditBookingCode(record.code)
                                  setEditBookingClinic(record.m5_timeslot?.clinicId)
                                  setEditBookingDate(record.m5_timeslot?.startTime)
                                  setEditBookingTimeslot(record.timeslotId)
                                  setUseExistingTimeslot(true)

                                setEditBookingId(record.id)
                              }} />
                            </td>
                          </tr>
                        )
                      })
                    }
                  </table>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
}


}
