import getPaymentStatus from './getPaymentStatus';

const formatDateObject = (date)=>{
  return (new Date(date).getDate())+"/"+(new Date(date).getMonth()+1)+"/"+(new Date(date).getFullYear())
}

const filterBookings = (bookings,filters)=>{
  let filteredArray = bookings
  if(filters.filterStartDate && !filters.filterEndDate){
    console.log(filters.filterEndDate)
    filteredArray = filteredArray.filter((booking)=>{ return formatDateObject((booking['m5_timeslot']?.startTime || booking.startTime)) == formatDateObject(filters.filterStartDate) })
  
  }else{
    if(filters.paymentStatusFilter && filters.paymentStatusFilter !== "All") filteredArray = filteredArray.filter((booking)=>{ return getPaymentStatus(booking) === filters.paymentStatusFilter})
    if(filters.filterStartDate) filteredArray = filteredArray.filter((booking)=>{ return (booking['m5_timeslot']?.startTime || booking.startTime) > filters.filterStartDate })
    if(filters.filterEndDate) filteredArray = filteredArray.filter((booking)=>{ return (booking['m5_timeslot']?.startTime || booking.startTime) < filters.filterEndDate })
  }
  
  return filteredArray
}

export default filterBookings
