import { useRoutes } from "react-router-dom";
import { Auth } from "../views/auth";
import { Clinics } from "../views/locations";
import { Confirm } from "../views/confirm";
import { Referrals } from "../views/referrals";
import { Timeslots } from "../views/timeslots";
import { Reset } from "../views/reset";
import { ResetPassword } from "../views/resetPassword";
import { Patients } from "../views/patients";
import { Bookings } from "../views/bookings";
import { Requests } from "../views/requests";
import { Pay } from "../views/pay";
import { SetTime } from "../views/setTime";
import { Success } from "../views/success";
import { Complete } from "../views/complete";
//import { NotFound } from "../views/notFound";

export const Routes = () => {
  const element = useRoutes(routes);
  return element;
};

const routes = [
  {
    element: <Auth />,
    path: "/",
  },
   {
    element: <Bookings />,
    path: "dashboard",
  },
{
 element: <Referrals />,
 path: "referrals",
},
{
 element: <Timeslots />,
 path: "timeslots",
},
{
 element: <Confirm />,
 path: "confirm",
},
{
 element: <Reset />,
 path: "reset",
},
{
 element: <ResetPassword />,
 path: "reset-password",
},
{
 element: <Clinics/>,
 path: "clinics",
},
{
 element: <Patients/>,
 path: "patients",
},
{
 element: <Bookings/>,
 path: "bookings",
},
{
 element: <Pay/>,
 path: "pay",
},
{
  element: <SetTime/>,
  path: "set-time",
 },
{
 element: <Success/>,
 path: "success",
},
{
 element: <Complete/>,
 path: "complete",
},
{
  element: <Requests/>,
  path: "requests",
 }
  /* { path: "*", element: <NotFound /> }, */
];
